<template>
  <div id="case">
    <img
      class="logo"
      src="../assets/image/bn6.jpg"
      alt=""
    >
    <div class="case_content">
      <div
        id="building"
        class="case_title"
      >
        <h1>智慧楼宇</h1>
        <p>
          平台服务与行业需求深度结合，基于数字孪生技术，形成一系列可视化产品，成功应用于智慧校园、智慧园区、智慧工厂、C2M电商、智慧机房、智慧交通、智慧能源。
        </p>
        <img
          src="../assets/image/case1.png"
          alt=""
        >
      </div>
      <div
        id="community"
        class="case_title"
      >
        <h1>智慧社区</h1>
        <p>
          运用云计算、大数据、物联网等现代科技手段，实现视频数据采集全面化、数据加工处理智能化、治安防控群众化、管理服务应用深度化
        </p>
        <img
          src="../assets/image/case12.png"
          alt=""
        >
      </div>
      <div
        id="carving"
        class="case_title"
      >
        <h1>5G云雕刻</h1>
        <p>
          1、激光雕刻技术能同时达到雕刻、切割、抛光。2、激光进行加工，非接触式加工。3、能耗低、无噪音、无污染，属节能环保型的加工方法。4、雕刻精度高、速度快，能对极其细微的部位进行雕刻，且雕刻效果好。
        </p>
        <img
          src="../assets/image/case2.png"
          alt=""
        >
      </div>
      <div
        id="party"
        class="case_title"
      >
        <h1>智慧党建</h1>
        <p>
          运用信息技术、网络技术和物联网感知技术等现代信息技术，构建党建发展的智慧环境，形成一个具备实时感知，互联互通、智能分析、扁平管理、服务党员等的全新的机关党建工作模式。
        </p>
        <img
          src="../assets/image/case3.png"
          alt=""
        >
      </div>
      <div
        id="fire"
        class="case_title"
      >
        <h1>智慧消防</h1>
        <p>
          平台服务与行业需求深度结合，基于数字孪生技术，形成一系列可视化产品，成功应用于智慧校园、智慧园区、智慧工厂、C2M电商、智慧机房、智慧交通、智慧能源。
        </p>
        <img
          src="../assets/image/case4.png"
          alt=""
        >
      </div>
      <div
        id="office"
        class="case_title"
      >
        <h1>智慧办公</h1>
        <p>
          通过统一物联网管理平台和APP，可实现智能访客设备、人脸门禁设备、智慧办公设备、智慧食堂消费机、车辆门禁设备、智能会议设备统一入口，统一管理。
        </p>
        <img
          src="../assets/image/case5.png"
          alt=""
        >
      </div>
      <div
        id="marathon"
        class="case_title"
      >
        <h1>智慧马拉松</h1>
        <p>
          平台服务与行业需求深度结合，基于数字孪生技术，形成一系列可视化产品，成功应用于智慧校园、智慧园区、智慧工厂、C2M电商、智慧机房、智慧交通、智慧能源。
        </p>
        <img
          src="../assets/image/case6.png"
          alt=""
        >
      </div>
      <div
        id="travel"
        class="case_title"
      >
        <h1>智慧旅游平台</h1>
        <p></p>
        <img
          src="../assets/image/case7.png"
          alt=""
        >
      </div>
      <div
        id="park"
        class="case_title"
      >
        <h1>智慧园区</h1>
        <p></p>
        <img
          src="../assets/image/SmartCommunity.png"
          alt=""
        >
      </div>
      <div
        id="exhibition"
        class="case_title"
      >
        <h1>智慧展厅</h1>
        <p></p>
        <img
          src="../assets/image/case10.png"
          alt=""
        >
      </div>
      <div
        id="campus"
        class="case_title"
      >
        <h1>智慧校园</h1>
        <p></p>
        <img
          src="../assets/image/case11.png"
          alt=""
        >
      </div>
    </div>
    <!-- 页尾 -->
    <Footer />
  </div>
</template>


<script>
import Footer from '../components/Public/Footer.vue'

export default {
  name: "Case",
  components: {
    Footer
  },
  data() {
    return {
      caseData: [
        {
          id: "building",
          url: require('../assets/image/case1.png'),
          name: "智慧楼宇",
          text: "平台服务与行业需求深度结合，基于数字孪生技术，形成一系列可视化产品，成功应用于智慧校园、智慧园区、智慧工厂、C2M电商、智慧机房、智慧交通、智慧能源"
        },
        {
          id: "carving",
          url: require('../assets/image/case2.png'),
          name: "5G云雕刻",
          text: "1、激光雕刻技术能同时达到雕刻、切割、抛光。2、激光进行加工，非接触式加工。3、能耗低、无噪音、无污染，属节能环保型的加工方法。4、雕刻精度高、速度快，能对极其细微的部位进行雕刻，且雕刻效果好。"
        },
        {
          id: "party",
          url: require('../assets/image/case3.png'),
          name: "智慧党建",
          text: "运用信息技术、网络技术和物联网感知技术等现代信息技术，构建党建发展的智慧环境，形成一个具备实时感知，互联互通、智能分析、扁平管理、服务党员等的全新的机关党建工作模式。"
        },
        {
          id: "fire",
          url: require('../assets/image/case4.png'),
          name: "智慧消防",
          text: ""
        },
        {
          id: "office",
          url: require('../assets/image/case5.png'),
          name: "智慧办公",
          text: "通过统一物联网管理平台和APP，可实现智能访客设备、人脸门禁设备、智慧办公设备、智慧食堂消费机、车辆门禁设备、智能会议设备统一入口，统一管理。"
        },
        {
          id: "marathon",
          url: require('../assets/image/case6.png'),
          name: "智慧马拉松",
          text: "“智慧马拉松” 提供RFID芯片，健康手环，温湿度传感器，重量传感器和高清摄像头五种类型，也可根据不同客户需求，定制化接入不同的物联网前端设备,为个人用户提供赛事视频交互小程序，给参赛运动员和亲友团提供赛事视频交互的平台；为组委会提供赛事应急指挥调度平台。"
        },
        {
          id: "travel",
          url: require('../assets/image/case7.png'),
          name: "智慧旅游平台",
          text: ""
        },
        {
          id: "community",
          url: require('../assets/image/SmartCommunity.png'),
          name: "智慧社区",
          text: ""
        },
        {
          id: "exhibition",
          url: require('../assets/image/case10.png'),
          name: "智慧展厅",
          text: ""
        },
        {
          id: "campus",
          url: require('../assets/image/case11.png'),
          name: "智慧校园",
          text: ""
        },
      ]
    }
  },
  computed: {
    listenshowpage1() {
      return this.$store.state.isConfigShow;
    }
  },
  watch: {
    listenshowpage1: function (a, b) {
      if (a != b) {
        var id = "#" +this.$store.state.name;
        // // 利用scrollIntoView这个api实现自动跳转
        document.querySelector(id).scrollIntoView(false);
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      var id = "#" + this.$route.params.id;
      // // 利用scrollIntoView这个api实现自动跳转
      document.querySelector(id).scrollIntoView(false);
    }
    if (this.$route.query.name !== undefined) {
      let id = "#" + this.$route.query.name;
      document.querySelector(id).scrollIntoView(false);
    }

    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
  }
}
</script>


<style lang="scss">
#case {
  padding-top: 60px;
  height: auto;
  .logo {
    width: 100vw;
  }
  .case_content {
    width: 1160px;
    height: auto;
    padding: 100px 0;
    margin: 0 auto;
    .case_title {
      margin-bottom: 30px;
      h1 {
        font-size: 28px;
        color: #333;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p {
        text-indent: 2em;
        line-height: 35px;
        margin-bottom: 15px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
@media (max-width: 1160px) {
  #case {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .case_content {
      width: 80vw;
      height: auto;
      padding: 100px 0;
      margin: 0 auto;
      .case_title {
        margin-bottom: 30px;
        h1 {
          font-size: 28px;
          color: #333;
          font-weight: bold;
          margin-bottom: 20px;
        }
        p {
          text-indent: 2em;
          line-height: 35px;
          margin-bottom: 15px;
          font-size: 18px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  #case {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .case_content {
      width: 80vw;
      height: auto;
      padding: 100px 0;
      margin: 0 auto;
      .case_title {
        margin-bottom: 30px;
        h1 {
          font-size: 25px;
          color: #333;
          font-weight: normal;
          margin-bottom: 20px;
        }
        p {
          text-indent: 2em;
          line-height: 35px;
          margin-bottom: 15px;
          font-size: 16px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 425px) {
  #case {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .case_content {
      width: 80vw;
      height: auto;
      padding: 100px 0;
      margin: 0 auto;
      .case_title {
        margin-bottom: 30px;
        h1 {
          font-size: 20px;
          color: #333;
          font-weight: normal;
          margin-bottom: 20px;
        }
        p {
          text-indent: 2em;
          line-height: 35px;
          margin-bottom: 15px;
          font-size: 14px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>